import React from 'react';
import './App.css';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { authService } from './services/authService';
import { Root } from './views/Root';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { AuthProvider } from './contexts/Auth';
import { SignIn } from './views/Signin';
import { TodayCircle } from './views/circle/TodayCircle';
import { secureFetch } from './Util';
import { Paths } from 'models/Paths';
import { Circle } from './views/Circle';
import { CircleOptions } from './views/circle/CircleOptions';
import { RouteIds, RoutePaths } from './Constants';
import { Helmet } from 'react-helmet';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdminDashboard } from './views/AdminDashboard';
import { Home } from './views/Home';
import { theme } from './themes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    id: RouteIds.Root,
    loader: async () => {
      try {
        const authData = await authService.refresh();
        return authData;
      } catch {
        return redirect(RoutePaths.Signin);
      }
    },
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/circle/:id',
        element: <Circle />,
        id: RouteIds.Circle,
        loader: async ({ params }) => {
          const res = await secureFetch(`/api${Paths.Circle.Base}${Paths.Circle.Get}`, 'POST', {
            circles: [params.id],
          });

          const circles = await res.json();
          return circles[0];
        },
        children: [
          {
            path: ':date',
            element: <TodayCircle />,
          },
          {
            path: 'options',
            element: <CircleOptions />,
          },
        ],
      },
      {
        path: '/adminDashboard',
        element: <AdminDashboard />,
      },
    ],
  },
  {
    path: RoutePaths.Signin,
    element: <SignIn></SignIn>,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Helmet>
          <title>Circles Journaling App</title>
        </Helmet>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

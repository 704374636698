import { Button, Stack, Typography } from '@mui/material';
import logo from '../logo.svg';
import styles from './Signin.module.css';

export const SignIn = () => {
  return (
    <Stack className={styles.container}>
      <img src={logo} alt="logo" className={styles.logo} />
      <Typography variant="h3">Log in to your Circle.</Typography>

      <div className={styles.signInButton}>
        <Button size="large" onClick={() => (window.location.href = '/api/auth/google')} variant="contained" color="primary">
          Google
        </Button>
      </div>
    </Stack>
  );
};

import React, { createContext, useState, useContext, PropsWithChildren } from 'react';
import { authService } from '../services/authService';
import { IUser } from 'models/User';

type AuthContextData = {
  user?: IUser;
  signOut(): void;
  refreshUser(): void;
};

//Create the Auth Context with the data type specified
//and a empty object
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<IUser>();

  const signOut = async () => {
    //Remove data from context, so the App can be notified
    //and send the user to the AuthStack
    setUser(undefined);

    await authService.signOut();
  };

  const refreshUser = async () => {
    const user = await authService.refresh();
    setUser(user);
  };

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <AuthContext.Provider value={{ user, signOut, refreshUser }}>{children}</AuthContext.Provider>
  );
};

//A simple hooks to facilitate the access to the AuthContext
// and permit components to subscribe to AuthContext updates
function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };

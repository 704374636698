import React, { useCallback, useEffect, useState } from 'react';

import { getMonthFromDatestring, secureFetch } from '../../Util';
import { IMonthEntries } from 'models/Entry';
import { Paths } from 'models/Paths';
import { Badge, Box, Typography } from '@mui/material';
import { DateCalendar, DayCalendarSkeleton, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { RouteIds } from '../../Constants';
import { ICircle } from 'models/Circle';
import { IUser } from 'models/User';

const ServerDay = (props: PickersDayProps<DateTime> & { markedDates?: { [key: string]: string[] } }) => {
  const { markedDates = {}, day, outsideCurrentMonth, ...other } = props;

  const isSelected = !props.outsideCurrentMonth && markedDates[day.toISODate() ?? ''];

  return (
    <Badge key={props.day.toString()} overlap="circular" badgeContent={isSelected ? '✅' : undefined}>
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
};

export const CircleHistory = () => {
  const circle = useRouteLoaderData(RouteIds.Circle) as ICircle;
  const sessionUser = useRouteLoaderData(RouteIds.Root) as IUser;

  const navigate = useNavigate();

  const [journalEntries, setJournalEntries] = useState<IMonthEntries>({});

  const { date } = useParams();

  if (circle === undefined) {
    throw Error();
  }

  const updateJournalEntries = useCallback(
    (dateString: string) => {
      secureFetch(`/api/${Paths.Journal.Base}/${Paths.Journal.Month}`, 'POST', {
        circle: circle.id,
        month: getMonthFromDatestring(dateString),
      }).then((res) => {
        if (res.ok) {
          res.json().then((entries: IMonthEntries) => {
            setJournalEntries(entries);
          });
        }
      });
    },
    [circle.id]
  );

  const onDatePress = useCallback(
    (dateString: string) => {
      if (dateString === date) {
        return;
      }
      navigate(`../${dateString}`);
    },
    [navigate, date]
  );

  useEffect(() => {
    updateJournalEntries(date ?? '');
  }, [date, updateJournalEntries]);

  // construct marked dates object
  // to mark the proper dates on the calendar accordingly
  const markedDates: { [key: string]: string[] } = {};

  for (const user in journalEntries) {
    journalEntries[user].forEach((entry) => {
      const dots = markedDates[entry] ?? [];
      dots.push(user === sessionUser.id ? 'userEntry' : 'otherUser');
      markedDates[entry] = dots;
    });
  }

  return (
    <Box>
      <Typography>Welcome to {circle.name}</Typography>
      <DateCalendar
        onChange={(date: DateTime) => {
          onDatePress(date.toISODate() ?? '');
        }}
        onMonthChange={(month) => {
          onDatePress(month.toISODate() ?? '');
        }}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            markedDates,
          } as any,
        }}
        value={DateTime.fromISO(date || '2021-01-01')}
      ></DateCalendar>
    </Box>
  );
};

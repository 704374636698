import { IUser } from 'models/User';
import { secureFetch } from '../Util';

const signOut = async (): Promise<void> => {
  // get token
  const apiURL = `/api/auth/logout`;

  const res = await fetch(apiURL, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error(await res.text());
  }
};

// refresh the token if valid
const refresh = async (): Promise<IUser> => {
  const apiURL = `/api/auth/refresh`;

  const res = await secureFetch(apiURL, 'POST');

  if (res.ok) {
    const user = await res.json();
    return user;
  } else {
    throw new Error(await res.text());
  }
};

export const authService = {
  signOut,
  refresh,
};

import React, { useCallback, useEffect, useState } from 'react';
import { getMonthFromDatestring, secureFetch } from '../Util';
import { IEntry } from 'models/Entry';
import { editEntry, getPromptForDay } from '../services/journalService';
import { Paths } from 'models/Paths';
import { DateTime } from 'luxon';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';

export const JournalEditor = (props: { selectedDate: string; circleId: string }) => {
  const { selectedDate, circleId } = props;

  const [promptText, setPromptText] = useState('');

  const [entryText, setEntryText] = useState('');
  const [loadingEntryText, setLoadingEntryText] = useState(false);

  const [changesMade, setChangesMade] = useState(false);

  const [loadingSave, setLoadingSave] = useState(false);

  const getSelectedEntry = useCallback(async () => {
    const res = await secureFetch(`/api/${Paths.Journal.Base}/${Paths.Journal.Get}`, 'POST', {
      circle: circleId,
      datestring: selectedDate,
    });

    if (res.ok) {
      const entry = await res.json();
      setEntryText(entry.entry);
    } else {
      setEntryText('');
    }
    setLoadingEntryText(false);
    setChangesMade(false);
  }, [circleId, selectedDate]);

  const onSubmitPress = useCallback(() => {
    setLoadingSave(true);

    const newEntry: IEntry = {
      id: selectedDate,
      circle: circleId,
      email: '',
      month: getMonthFromDatestring(selectedDate),
      content: entryText,
    };

    editEntry(newEntry).then((ok) => {
      setChangesMade(!ok);
      setLoadingSave(!ok);
    });
  }, [circleId, entryText, selectedDate]);

  useEffect(() => {
    // get the user's entry for the day
    setLoadingEntryText(true);
    setEntryText('');
    setPromptText('');

    const fetchEntry = getSelectedEntry();
    const fetchPrompt = getPromptForDay(circleId, selectedDate).then((prompt) => setPromptText(prompt));

    // reset loading after entry and prompt are loaded
    Promise.all([fetchEntry, fetchPrompt]).then(() => {
      setLoadingEntryText(false);
      setChangesMade(false);
    });
  }, [circleId, getSelectedEntry, selectedDate]);

  useEffect(() => {
    // for autosaving after changes have been made
    const intervalId = setTimeout(() => {
      if (changesMade) {
        onSubmitPress();
      }
    }, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [entryText, changesMade, onSubmitPress]);

  const onEntryUpdate = (entryText: string) => {
    setEntryText(entryText);
    if (!changesMade) {
      setChangesMade(true);
    }
  };

  const datetimeObj = DateTime.fromISO(selectedDate);
  const localizedDate = datetimeObj.toLocaleString({
    weekday: 'long',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

  return (
    <Stack>
      <Typography variant="h6">Entry for {localizedDate}</Typography>
      <Typography>{promptText}</Typography>
      <TextField label="Start your story" value={entryText} onChange={(e) => onEntryUpdate(e.target.value)} multiline variant="outlined" fullWidth />
      {loadingEntryText ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" onClick={onSubmitPress} disabled={!changesMade || loadingSave}>
          {loadingSave ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      )}
    </Stack>
  );
};
